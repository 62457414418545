<template>
    <div class="login">
        <div class="login-con">
            <p class="fs-32 c-FE letter-5">业务管理系统</p>

            <p class="c-FE mt-15 fs-16 mb-45"></p>
            <div class="width374">
                <el-input
                        placeholder="请输入用户名称"
                        prefix-icon="el-icon-user-solid"
                        v-model="input.username">
                </el-input>
                <el-input
                        placeholder="请输入密码"
                        prefix-icon="el-icon-key"
                        v-model="input.password"
                        type="password"
                >
                </el-input>
                <el-button type="primary" v-on:click="login()">登录</el-button>
            </div>
        </div>
       
        <div class="footer">
            <a href="https://beian.miit.gov.cn" style="color: white;">渝ICP备2021000930号-1</a>
            <p>重庆追电数字科技有限公司版权所有</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'login',
        mounted() {
            this.$user.checkLogin(() => {
                //this.$message.success('登录成功,开始跳转:' + this.$user.getReferer())
                this.$router.push(this.$user.getReferer());
            })
        },
        components: {},
        data() {
            return {
                input: {
                    username: '',
                    password: ''
                }
            }
        },
        methods: {
            login() {
                this.$loading.show('登录中');
                this.$api.user.login(this.input).then(result => {
                    if (result.errCode) {
                        this.$loading.close();
                        return this.$message.error(result.message)
                    }
                    this.$user.init()
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.$loading.close();
                })
            }
        },
    }
</script>
